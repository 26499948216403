.subnav{
  li{
    width: 100%;
    display: table;
    line-height: 40px;
    background-color: $brand-primary;
    border-bottom: 1px solid darken($brand-primary, 10%);
    @include transition(all .5s ease);
    &.nav-selected, &.nav-path-selected, &:hover{
      background-color: $boileau-red;
      //border-bottom: 1px solid darken($boileau-red, 10%);
    }
    a{
      color: $white;
      text-decoration: none;
      font-weight: 600;
      font-size: 15px;
      //display: block;
      padding: 0 10px;
    }
    &.has-children{
      .menu-group-indicator{
        float: right;
        margin: 0 15px 0 0!important;
        padding: 0 0 0 10px!important;
        color: $white;
      }
      .sub-nav{
        list-style-type: none;
        position: relative!important;
        top: 0!important;
        float: none!important;
        border:none;
        li{
          padding-left:10px!important;
          border-bottom: none;
        }
      }
    }
  }
}
.nav{
  li{
    float: left;
    &.has-children{
      .menu-group-indicator{
        background: transparent;
        border: none;
        font-size: 12px;
        padding: 10px 15px;
        margin: 10px 0 0 -20px;
        cursor: pointer;
        &:focus{
          outline: none;
        }
      }
      a{
      }
      ul.sub-nav{
        display: none;
        position: absolute;
        padding: 0;
        top: 55px;
        background-color: $white;
        //min-width: 160px;
        right: auto;
        float: left;
        @include box-shadow(-1px -1px 3px rgba(0, 0, 0, 0.06));
        li{
          width: 100%;
        }
      }
    }
  }
}
#main-menu{
  .nav{
    li.has-children:hover{
      ul.sub-nav{
        display: block;
      }
    }
    ul.sub-nav{
      //width: 300px!important;
     // min-width: 0;
      width: auto;
      li{
        width: 100%;
        a{
          width: auto;
          line-height: 20px;
          padding: 10px 10px 0 10px!important;
        }
      }
    }
    a{
      float: left;
    }
  }
}