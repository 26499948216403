@include media-breakpoint-down(sm){
  .gallery-wrapper{
    .card-columns{
      @include columns(2, .75rem);
    }
  }
}
@include media-breakpoint-up(sm){
  .gallery-wrapper{
    .card-columns{
      @include columns(5, .75rem);
    }
  }
}
@include media-breakpoint-down(md){
  header .nav.original {
    display: none!important
  }
  #mobileMainNav {
    //display: none !important;
  }
}
@include media-breakpoint-up(md){
  #mobileMainNav {
    display: none !important;
  }
}
@include media-breakpoint-down(lg){
  #menu-toggle{
    display: block;
  }
  .subnav li{
    float: none!important;
  }
  #mobileMainNav{
    width:250px;
    background-color: $brand-primary;
    position: fixed;
    height: 1000px;
    overflow-y: auto;
    top:0;
    left:0;
    visibility: hidden;
    z-index:0;
    display: block!important;
    @include transition(visibility .0s linear .1s);
    &.active{
      height:100%;
      visibility: visible;
    }
    .header-logo{
      background-color: $brand-primary;
      margin-top: 20px;
      a {
        display: block;
        height: 82px;
        padding:10px;
        img{
          width:auto;
          height: 100%;
        }
      }
    }
    .nav-mobile{
      .nav{
        margin:0;
        padding:0;
        background-color: $brand-primary;
        display: inline-grid;
        width: 100%;
        li{
          margin-top:1px;
          &:last-child{
            margin-bottom:1px;
          }
          &.has-children{
            .menu-group-indicator{
              display: block;
              position: absolute;
              padding:5px 10px;
              right:20px;
              margin-top:8px;
              color: $white;
              border: 0;
              background-color: transparent;
              &:active,&:focus{
                outline: none;
              }
            }
          }
          &:hover{
            >a{
              background-color:darken($brand-primary,10%);
              width:100%;
            }
          }
          a{
            color:  $white;
            padding:12px 20px;
            width:100%;
            float:left;
            display:block;
            background-color: $brand-primary;
            .sr-only{
              display: none;
            }
            span{
              padding-top:3px;
              position: relative;
              display: inline-block;
              width: auto;
              font-weight: 700;
              font-size: 1em;
              opacity: 1;
              margin:0;
              color:$white;
              float:left;
            }
            &.active,&.nav-path-selected{
              background-color:darken($brand-primary,20%);
              width:100%;
              span{
                margin-left:0;
              }
            }
          }
          .sub-nav{
            position: relative;
            width: 100%;
            opacity:1;
            left:auto;
            float:left;
            display:none;
            list-style: none;
            background-color:$brand-primary;
            padding-left:0px;
            &.open{
              display: block;
            }
            li{
              &:hover{
                >a{
                  text-decoration:none;
                  background-color:darken($brand-primary,10%);
                  width:100%;
                }
              }
              a{
                font-size: 0.8em;
                padding:9px 10px 9px 20px;
                span{
                  display:inline-block;
                  margin-left:0px;
                  margin-top:0px;
                }
              }
              .sub-nav{
                display:none;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg){
  #menu-toggle{
    display: none;
  }
}