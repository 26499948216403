// -----------------------------------------------------------------------------
// Early imports and overrides
// -----------------------------------------------------------------------------
@import "theme/variables";
@import "theme/mixins";

// -----------------------------------------------------------------------------
// Vendor resources
// -----------------------------------------------------------------------------
@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";

// -----------------------------------------------------------------------------
// Theme components
// -----------------------------------------------------------------------------
@import "theme/elements/body";
// Wrap all the theme specific css in the page wrapper class to avoid collision with the CMS styling
div.ccm-page{
  // Theme basic elements
  @import "theme/elements/header";
  @import "theme/elements/main";

  // Theme style components
  @import "theme/components/forms";

  // Theme blocks
  @import "theme/blocks/autonav";
  @import "theme/blocks/buttons";
  @import "theme/blocks/date-navigation";
  @import "theme/blocks/image_slider";
  @import "theme/blocks/menucard";
  @import "theme/blocks/page-list";
  @import "theme/blocks/testimonials";
  @import "theme/blocks/vivid-gallery";

  // Theme custom block design classes
  @import "theme/custom-block-styles/testemonial-on-blanco";
  @import "theme/custom-block-styles/block-sidebar-wrapped";
}

@import "theme/elements/mobile-navigation";