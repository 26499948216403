div.ccm-block-date-navigation-header {
  font-size: 1em;
  h1{
    color:$brand-primary;
  }
}

ul.ccm-block-date-navigation-dates {
  list-style-type: none;
  padding-left: 0px;
  padding-bottom: 0px;

  li {
    margin-bottom: ($line-height-base / 2);
    padding-bottom: 0px;
    @include transition(all 0.3s linear);
    i{
      display:none;
      color: $brand-primary!important;
      margin-right:10px;
    }
    a{
      color: $brand-primary!important;
    }
    &:hover{
      a{
        color: $brand-primary!important;
      }
      i{
        display:inline-block;
      }
    }
  }
}

.ccm-block-date-navigation-date-selected {
  font-weight: bold;
  &:before{
    margin-right:0.5rem;
    @include fa-icon();
    @include fa(arrow-right);
  }
}