@mixin fa($icon) {
  @extend .fa-#{$icon}
}
@mixin columns($columns, $gap){
  -webkit-column-count: $columns;
  -moz-column-count: $columns;
  column-count: $columns;
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}