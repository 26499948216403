.ccm-block-testimonial-wrapper{
  padding:0.5rem;
  margin-bottom: 1rem;
  margin-left: -15px;
  margin-right: -15px;
  min-height: 186px;
  .ccm-block-testimonial-image{
    position: absolute;
    width: 150px;
    img{
      width: 100%;
      height: auto;
      max-height: 200px;
    }
  }
  .ccm-block-testimonial-text{
    padding-left: 160px;
    .ccm-block-testimonial-position {
      font-style: italic;
    }
    .ccm-block-testimonial-name {
      font-weight: bold;
    }
  }
}
@include media-breakpoint-down(xs){
  .ccm-block-testimonial-wrapper{
    .ccm-block-testimonial-image {
      position: relative;
    }
    .ccm-block-testimonial-text {
      padding-left: 0px;
    }
  }
}