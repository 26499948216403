.btn-default{
  background-color: $brand-primary;
  color: $white;
  &:hover{
    background-color: $boileau-red;
  }
}
.btn{
  margin: 0 0 10px 0;
  i.fa{
    margin-left: 5px;
    font-size: 14px;
  }
}