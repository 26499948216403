.category-title span{
  background-color: transparent;
  font-size: 24px;
  color: $black;
}
.menucard-category{
  margin-bottom: 20px;
}
.menucard-dish{
  border-bottom: 1px dashed $brand-primary;
  h4{
    font-size: 18px;
    font-weight: 200;
  }
  .menucard-dish-description{
    font-weight: 200;
  }
  .menucard-dish-price, .menucard-dish-price span{
    font-weight: 400!important;
  }
}
#categoryName{
  font-weight: 600;
  color: $brand-primary;
}
.category-nav {
  margin-bottom: 20px;
  background-color: transparent!important;
  .category-nav-item.active {
    background-color: $boileau-red;
  }
  .category-nav-item {
    float:none;
    @include transition(background-color .5s ease);
    background-color: $brand-primary;
    padding: 10px;
    a{
      color: $white;
      text-decoration: none;
    }
  }
}
#dishList{
  >div{
    float: left;
  }
}