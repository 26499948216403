div.block-sidebar-wrapped {
  //padding: 1rem 2rem;
  //background-color: $brand-primary;
  font-size: 0.85rem;

  h3 {
    //margin: 0px;
    //font-size: 1em;
    //font-weight: normal;
  }

  a {
    //color: $white !important;
    &:hover {
      text-decoration: none;
      //color: $white !important;
    }
  }

  div.ccm-block-page-list-page-entry {
    padding-bottom: 0px;
    border-bottom: 0px;
    margin-bottom: 0px;
  }

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    //color: $white !important;
    li {
      margin-bottom: 12px;
    }

    ul {
      padding-left: 1rem;
      //font-size: 1rem;
    }
  }

}

div.block-sidebar-wrapped + div.block-sidebar-wrapped {
  padding-top: 1rem;
}

// blocks in blog entry
div.block-sidebar-wrapped {
  div.ccm-block-share-this-page {
    //margin: -15px -40px -25px -40px;
    //padding: 15px 40px 10px 40px;
    padding-top:1rem;
    border-top: 1px solid $brand-primary !important;;
    font-size:1.5rem;

    a {
      //color: $brand-primary !important;
      &:hover {
        color: darken($brand-primary,20%);
      }
    }
  }
}