header{
  position: relative;
  background-color: $header-background-color;
  padding: .8rem 0;

  .logo{
    height: auto;
    display: block;
    img{
      width:100%;
      height: auto;
    }
  }

  #main-menu{
    //@include make-container(1rem);
    padding:0 1rem;
    &.navbar-fixed-top{
      background-color: white;
      .sub-nav{
        top:50px;
      }
    }
    nav{
      &.navbar-fixed-top{
        ul.nav{
          text-align:center;
          float:right;
        }
      }
    }
    .nav{
      float:right;
      li{
        display: inline-block;
        *display: inline;
        *zoom: 1;
      }
      a{
        color:$black;
        padding:1rem 1rem 0.5rem;
        display:block;
        font-size: 15px;
        font-weight: 700;
        &:after{
          content: '';
          display: block;
          border-bottom: 2px solid $brand-primary;
          width: 0;
          margin-top:.5rem;
          @include transition(0.3s ease-in-out)
        }
        &:link{
          text-decoration: none;
        }
        &:hover,&.nav-path-selected{
          outline: none;
          &:after{
            width: 100%;
          }
        }
        &.nav-path-selected{
          font-weight: 700;
        }
      }
    }
  }
  #menu-toggle{
    background-color: $brand-primary;
    padding:0.6rem 0.8rem;
    border:none;
    i{
      color:$white;
    }
    &:focus{
      outline:0;
    }
  }
  #logo-row{
    @include make-row();
    margin:0;
    padding:1rem 0;
    #header-logo{
      height:150px;
      display: block;
      img{
        height:100%;
        width: auto;
      }
    }
  }
}