.gallery-wrapper{
  width: 100%;
  margin: 0 0 25px 0;
  padding: 2px 2px 0 0;
  .gallery-item{
    float: left;
    background-color: transparent;
    border: none;
    &:hover{
      img{
        opacity:.8;
      }
    }
    img{
      width:100%;
      height:auto;
      @include transition(all .2s ease);
    }
  }
}