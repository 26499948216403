.rslides_tabs{
  list-style-type:none;
  display: table-cell!important;
  display: block!important;
  margin: 0 auto 25px;
  width: 100px;
  li{
    display:inline-block;
    float:left;
    margin-right:5px;
    border-radius:50%;
    border: 2px solid black;
    width:15px;
    height:15px;
    a{
      width:15px;
      height:15px;
      display: block;
      color:transparent;
    }
  }
  li.rslides_here{
    border-radius:50%;
    border: 2px solid black;
    background-color: black;
    a{
      content:'/'!important;
    }
  }
}
.rslides_thumbs{
  list-style-type: none;
  padding-left: 0;
  top:10px;
  display: block!important;
  text-align: center;
  .rslides_thumbs_container{
    display: inline-block!important;
    margin:0 auto;
  }
  .slider-thumb{
    float:left;
    margin-right:10px;
    a{
      height:100px;
      width:150px;
      display:block;
      background-size:cover!important;
    }
  }
}

.prev{
  left:20px;
}
.next{
  right:20px;
}
.rslides_nav{
  width:40px;
}

.default-slider .rslides{
  .ccm-image-slider-text{
    font-weight: 600;
    left: 5%;
    top: 10%;
    padding: 20px;
    text-shadow: 1px 1px 1px black;
    position: absolute;
    display: inline-table;
    width: 40%;
    a:hover{
      text-decoration: none!important;
    }
    h2, p{
      color: white;
    }
  }
}
.thumb-slider{
  .ccm-image-slider-text{
    position:absolute;
    margin-top:20%;
    text-align:center;
    width:100%;
    div{
      display:block;
      text-align: center;
      height:auto;
      position:relative;
      min-height:40px;
      margin:10px 0;
    }
    h2{
      background:rgba(255,255,255,.6);
      color:black;
      padding:10px 25px;
      text-align:center;
      font-size:15px;
      width:auto;
      display: inline;
      position:relative;
      height:auto;
    }
    p{
      background:rgba(0,0,0,.6);
      color:white;
      padding:10px 25px;
      font-size:15px;
      width:auto;
      display: inline;
      position:relative;
      height:auto;
    }
  }
}
ul.rslides {
  margin-bottom:10px;
}

///CUSTOM TEMPLATE LOGO SLIDER
@include media-breakpoint-down(md) {
  .logo-slider{
    padding-top:0px!important;
    height:110px!important;
    margin-bottom:0px!important;
    .outer-scroller{
      height:110px!important;
    }
  }
}
.logo-slider-header{
  color:$black;
  opacity:0.6;
  font-weight:400;
  text-align:center;
  font-size:16px;
}
.logo-slider{
  padding-bottom: 40px;
  padding-top:0px;
  width:100%;
  height:80px;
  float: none;
  position:relative;
  margin-bottom:10px;
  .outer-scroller{
    width:100%;
    height:80px;
    overflow:hidden;
    .inner-scroller{
      width:auto;
      height:100%;
      overflow:hidden;
      ul{
        list-style:none;
        height:100%;
        width:auto;
        @include transition(move 1s linear);
        li{
          width:130px;
          height:100%;
          margin:0 20px;
          cursor:pointer;
          position:relative;
          float:left;
          .slider-img{
            width:100%;
            height:100%;
            display:block;
            text-decoration:none;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:contain!important;
            -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
            -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
            -webkit-filter: grayscale(100%);
            filter: gray;
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
            opacity:0.9;
            @include transition(all 0.5s linear);
            &:hover {
              -webkit-filter:none;
              -moz-filter:none;
              -ms-filter:none;
              -o-filter:none;
              filter:none;
              opacity:1;
            }
          }
        }
      }
    }
  }
}
