main{
  margin-top: 2.5rem;
}
h1,h2{
  color: $boileau-red;
}
h3,h4{
  color: $brand-primary;
}
.img-responsive{
  width: 100%;
  height: auto;
}
.content-header{
   #page-title{
     background-color: $brand-primary;
     padding: 0.5rem 1rem;
     width: auto;
     display: table;;
     color: $white;
     text-align: center;
     font-size: 1.3rem;
     margin:0 auto 20px auto;
     font-weight: 600;
   }
 }
.content-container{
  margin-bottom:2.5rem;
  &.default-container,.main-content,.side-content{
    padding:1rem;
    background-color: rgba(255,255,255,0.7);
  }
  .side-content{
    &.left{
      padding: 0;
      background-color: transparent;
    }
  }
  &.blanco-container{
    padding:1rem 0;
  }
}
#scroll-top{
  position: fixed;
  bottom:1rem;
  right:1rem;
  z-index: 100;
  background-color:$brand-primary;
  padding:0.6rem 0.7rem;
  color:$white;
  border:none;
  display:none;
  &:focus{
    outline:0;
  }
}