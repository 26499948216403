@include media-breakpoint-up(md){
  body{
    position: absolute;
    background-image: $default-background-image;
    background-size: cover;
    background-position: 0% 0%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  body{
    width: 100%;
    min-height: 100%;
  }
}
@include media-breakpoint-down(md){
  body{
    background-image: $default-background-image;
  }
  .wrapper{
    z-index:10;
    //background-color: $white;
    &.pushable{
      @include transition(0.3s ease-in-out);
      &.pushed{
        position:fixed;
        -webkit-transform: translate(250px,0);
        -ms-transform: translate(250px,0); // IE9 only
        -o-transform: translate(250px,0);
        transform: translate(250px,0);
      }
    }
    @include media-breakpoint-down(sm){
      .pushed{
        @include box-shadow(-4px 0px 6px -3px rgba(0,0,0,0.3));
      }
    }
  }
}