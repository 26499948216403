.form-control{
   border-color: $brand-primary;
   border-radius: 0;
  &:focus{
    -webkit-box-shadow: 0px 0px 3px 3px rgba(0,82,156,0.6);
    -moz-box-shadow: 0px 0px 3px 3px rgba(0,82,156,0.6);
    box-shadow: 0px 0px 3px 3px rgba(0,82,156,0.6);
  }
  &.form-control-error{
    border-color:$brand-danger;
    &:focus{
      -webkit-box-shadow: 0px 0px 3px 3px rgba(206,118,105,0.6);
      -moz-box-shadow: 0px 0px 3px 3px rgba(206,118,105,0.6);
      box-shadow: 0px 0px 3px 3px rgba(206,118,105,0.6);
    }
  }
  &.form-control-success{
    border-color:$brand-success;
    &:focus{
      -webkit-box-shadow: 0px 0px 3px 3px rgba(113,191,68,0.6);
      -moz-box-shadow: 0px 0px 3px 3px rgba(113,191,68,0.6);
      box-shadow: 0px 0px 3px 3px rgba(113,191,68,0.6);
    }
  }
 }
.input-group-addon{
  border-color: $brand-primary;
  color:$brand-primary;
  background-color: $white;
}
.has-error .input-group-addon{
  border-color:$brand-danger;
  color:$brand-danger;
}
.has-success .input-group-addon{
  border-color:$brand-success;
  color:$brand-success;
}
#courseSignUpForm{
  input.form-control{
    height: 40px;
  }
}