// -----------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------
$black: #1A1A1A;
$white: #FFFFFF;
$blue: #00529C;
$green: #71BF44;
$red: #CE7669;
$orange: #F6C595;
$kiwi-green: #95b311;

$boileau-blue: #0187d0;
$boileau-red: #d10334;

// -----------------------------------------------------------------------------
// Bootstrap Variables
// -----------------------------------------------------------------------------
$brand-primary: $boileau-blue;
$brand-info: $blue;
$brand-success: $green;
$brand-danger: $red;
$brand-warning: $orange;

$font-family-sans-serif:  "Oxygen", Helvetica, Arial, sans-serif;
$headings-font-weight:700;

$enable-transitions: true;
$enable-rounded: false;

//TEST
$enable-flex:               false!default;
//$enable-shadows:            false !default;
//$enable-gradients:          false !default;
//$enable-hover-media-query:  false !default;

// -----------------------------------------------------------------------------
// Theme Variables
// -----------------------------------------------------------------------------
$default-background-image: url('../images/background.jpg');

$header-background-color: $white;