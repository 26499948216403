a.ccm-block-page-list-rss-feed{
  font-size: 1.5em;
  color: $brand-primary;

  &:hover {
    color: darken($brand-primary,20%);
  }
}

div.ccm-block-page-list-page-entry-horizontal {
  @include clearfix();
}

div.ccm-block-page-list-page-entry-horizontal,
div.ccm-block-page-list-page-entry {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $brand-primary;
  .ccm-block-page-list-date{
    font-size:0.8em;
    font-weight:700;
  }
}

div.ccm-block-page-list-page-entry-read-more {
  margin-top: 20px;
}

div.ccm-block-page-list-no-pages {
  text-align: center;
  font-size: $font-size-h3;
  color: $brand-primary;
}

div.col-sidebar {
  div.block-sidebar-wrapped {
    div.ccm-block-page-list-wrapper {
      margin: 0px;
    }
  }
}

div.col-sidebar {
  div.ccm-block-page-list-wrapper {
    margin: 25px 40px 25px 40px;
    font-size: .85em;

    a.ccm-block-page-list-rss-feed {
      font-size: .85em;
    }
    div.ccm-block-page-list-page-entry {
      border: 0px;
      padding: 0px;
      margin-bottom: ($line-height-base / 2);
      a {
        font-weight: normal;
      }
    }
  }
}

div.ccm-block-page-list-header {
  font-size: 1em;
}
.ccm-block-page-list-page-entry-thumbnail{
  img{
    height: auto;
  }
}
.ccm-block-page-list-thumbnail-grid-wrapper{
  .pagelist-banner-wrapper{
    width: 33.33%;
    padding: 0 15px 15px 0;
    float: left;
    .pagelist-banner-image{
      width: 100%;
      height: 200px;
      display: block;
      background-size: cover!important;
      h4{
        padding: 15px;
        color: white;
        text-decoration: none;
        text-shadow: 0 1px 1px black;
        &:hover{
          text-decoration: none!important;
        }
      }
    }
    .ccm-block-page-list-page-entry-grid-thumbnail-hover{
      display: none;
    }
  }
}